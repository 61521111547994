import { PREVIEW, REMOVE_PREVIEW_PARAM, DISABLE_METRICS } from './constants/common';
import getDeepLink from './getDeepLink';

export const handleReloadWithPreview = ({
  storefrontName,
  additionalParams = [],
} = {}) => {
  const url = new URL(window.location.href);
  url.searchParams.set(PREVIEW, 'true');
  url.searchParams.set(REMOVE_PREVIEW_PARAM, 'true');
  url.searchParams.set(DISABLE_METRICS, 'true');

  additionalParams.forEach(({ key, value }) => {
    url.searchParams.set(key, value);
  });

  const urlParse = url.toString();

  if (window.MobileWebKit?.isConnected?.()) {
    const { deepLink } = getDeepLink({ url: urlParse, storefrontName });
    window.MobileWebKit.navigation.push(deepLink, {
      replace: true,
    }).catch(() => {
      window.location.replace(urlParse);
    });
  } else {
    window.location.replace(urlParse);
  }
};

export const removePreviewParam = ({
  additionalParams = [],
} = {}) => {
  const url = new URL(window.location.href);
  if (url.searchParams.has(REMOVE_PREVIEW_PARAM)) {
    url.searchParams.delete(REMOVE_PREVIEW_PARAM);
    url.searchParams.delete(PREVIEW);
    url.searchParams.delete(DISABLE_METRICS);

    additionalParams.forEach(param => {
      url.searchParams.delete(param);
    });

    window.history.replaceState({}, '', url.toString());
  }
};
