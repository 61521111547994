import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CouponCarousel from '@mshops-components-library/coupon-carousel';

import { useRenderContext } from '../../pages/home/context';

import useCouponModal from './useCouponModal';
import useApplyCoupon from './useApplyCoupon';
import { trackEvent } from '../../utils/tracking';
import tracks from '../../utils/tracks';

const CouponCarouselComponent = (props) => {
  const { isEditable, initialSlideCouponCarousel, allowMetrics, draftMode, commonTrackData } = useRenderContext();
  const { visible, modal, coupons } = props;
  const isNotEmpty = coupons?.length > 0;
  const { applyCoupon, getCampaignInfo, couponsState } = useApplyCoupon();
  const { modalUpdate, modalActions } = useCouponModal({
    isEditable,
    modal,
    commonTrackData,
  });

  if (!visible) {
    return null;
  }

  useEffect(() => {
    if (isEditable || allowMetrics === false || draftMode || !visible || !isNotEmpty) { return; }

    trackEvent({
      ...tracks.coupons,
      melidata_event: {
        ...tracks.coupons.melidata_event,
        event_data: {
          ...tracks.coupons.melidata_event.event_data,
          type_coupon: 'not_apply',
          quantity: coupons?.length || 0,
        },
      },
    }, commonTrackData);
  }, []);

  return (
    <CouponCarousel
      {...props}
      modal={modalUpdate}
      modalActions={modalActions}
      coupons={couponsState || coupons}
      applyCoupon={applyCoupon}
      getCampaignInfo={getCampaignInfo}
      initialSlide={initialSlideCouponCarousel ?? 0}
      trackAppliedCoupon={{
        path: '/eshops/coupons/find_products',
        event_data: {
          ...commonTrackData,
          type_coupon: 'not_apply',
        },
      }}
      trackApplyCoupon={{
        path: '/eshops/coupons/activate',
        event_data: {
          ...commonTrackData,
          type_coupon: 'not_apply',
        },
      }}
    />
  );
};

CouponCarouselComponent.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  isEditable: PropTypes.bool,
  modal: PropTypes.shape({}),
};

export default CouponCarouselComponent;
